
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
    
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers"    :search="search" :items="rows" class="elevation-0"
                    item-key="">
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-flex>
</v-layout>
</v-container>
</div>
</template>
<script>
    import requests from './../../requests/shop_items_view.request.js'
    export default {
        data() {
            return {
                shop_items_view: {},
                search: '',
                loading : true,
                
                rows:[],
                headers: [

                    
                        { 
                            text: this.$store.getters.language.data.shop_items_view.shop_item_id,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_id',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.shop_item_barcode,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_barcode',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.shop_item_quantity,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_quantity',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.shop_item_note,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_note',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.shop_item_price,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_price',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.shop_item_fav,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_fav',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.shop_item_status,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_status',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.shop_item_date,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_date',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.item_type_id,
                            align: 'start',
                            sortable: true,
                            value: 'item_type_id',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.item_color_id,
                            align: 'start',
                            sortable: true,
                            value: 'item_color_id',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.item_size_id,
                            align: 'start',
                            sortable: true,
                            value: 'item_size_id',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.user_id,
                            align: 'start',
                            sortable: true,
                            value: 'user_id',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.item_type_title,
                            align: 'start',
                            sortable: true,
                            value: 'item_type_title',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.item_color_title,
                            align: 'start',
                            sortable: true,
                            value: 'item_color_title',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_items_view.item_size_title,
                            align: 'start',
                            sortable: true,
                            value: 'item_size_title',
                        },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: '',
        }
                ],
            }
        },
        mounted(){
            this.readShopItemsView();
        },
        methods: {
            readShopItemsView() {
                this.loading = true
                requests.getAllShopItemsView().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.rows
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read ShopItemsView',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read ShopItemsView',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    
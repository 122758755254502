
import axios from 'axios';
export default {
    
    async reportShopItemsView(column , value)  {
        return await axios.get(`shop_items_view/report?${column}=${value}`)
    },
    async getAllShopItemsView()  {
        return await axios.get(`shop_items_view/all`)
    },

    async getShopItemsViewByColumn(column , value)  {
        return await axios.get(`shop_items_view/filter?column=${column}&value=${value}`)
    }
}